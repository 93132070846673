import React, { Component } from 'react';
import classNames from 'classnames';

import * as Style from './style';

class SubLinks extends Component {
  getSubListHeight = (e, parent) => {
    const childrenCount = parent.children.length;
    const childHeight = 33;
    const height = childrenCount * childHeight;
    return height;
  }

  render() {
    const { parentIndex, parent } = this.props;
    const accordionClass = classNames({
      '-is-expanded': this.props.openParentID === parentIndex,
    });
    return (
      <Style.SubNavListWrapper className={accordionClass} childHeight={(e) => this.getSubListHeight(e, parent) }>
        <Style.SubNavList ref={this.subNavList}>
          {parent.children.map((child, index) => (
            <Style.LISub key={index}>
              <Style.SubLink to={parent.to+child.to}>{child.text}</Style.SubLink>
            </Style.LISub>
          ))}
        </Style.SubNavList>
      </Style.SubNavListWrapper>
    );
  }
}

export default SubLinks;
