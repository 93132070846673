import gql from 'graphql-tag';

export const TAXONOMY_NEWSLETTERS = gql`
  query TaxonomyNewsletters ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _newsletterStats {
        count
      }
      newsletters (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const NEWSLETTERS_QUERY = gql`
  query NewslettersQuery($page: Integer!, $perPage: Integer!) {
    newsletters(page: $page, perPage: $perPage) {
      id
      oid
      live
      title
      issues {
        oid
        id
        title
      }
    }
  }
`;

export const NEWSLETTERS = gql`
  query Newsletters {
    newsletters {
      id
      oid
      live
      title
      users {
        oid
        id
      }
      subscriptions {
        id
        oid
        user {
          id
          oid
          firstName
          lastName
        }
      }
    }

    _newsletterStats {
      count
    }
  }
`;

export const NEWSLETTER_ISSUES = gql`
  query NewsletterIssues($oid: Integer, $sortString: [Str], $perPage:Integer, $page:Integer) {
    newsletter(oid: $oid) {
      id
      live
      title
      issues(sortString: $sortString, page: $page, perPage: $perPage) {
        oid
        id
        title
        updatedAt
        volume
        issueNumber
        postAt
        articles {
          oid
          id
          title
          kicker
          teaser
          body
          sourceUrl
          sponsor {
            id
            title
          }
          authors {
            id
            firstName
            lastName
          }
          meta {
            slug
          }
          image {
            id
            url
            absoluteUrl
            altText
          }
          taxonomies {
            oid
            title
          }
        }
        _articleStats {
          count
        }
        classifieds {
          oid
          id
          title
        }
        _classifiedStats {
          count
        }
        contentProducts {
          oid
          id
          title: name
          meta {
            slug
          }
          image {
            id
            url
            absoluteUrl
            altText
          }
          taxonomies {
            oid
            title
          }
        }
        _contentProductStats {
          count
        }
        events {
          oid
          id
          title
          teaser
          beginAt
          meta {
            slug
          }
          image {
            id
            url
            absoluteUrl
            altText
          }
          taxonomies {
            oid
            title
          }
        }
        _eventStats {
          count
        }
      }
    }
  }
`;

export const NEWSLETTER_ADD_MUTATION = gql`
  mutation NewsletterAddMutation ($input: NewsletterCreateInput!) {
    newsletterCreate (input: $input) {
      newsletter {
        id
        oid
        title
        canonicalUrl
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_EDIT_MUTATION = gql`
  mutation NewsletterEditMutation ($input: NewsletterUpdateInput!) {
    newsletterUpdate (input: $input)
    {
      newsletters { id }
      success
      errors
    }
  }
`;

export const NEWSLETTER_STATS = gql`
  query NewsletterStats {
    _newsletterStats {
      count
    }
  }
`;

export const NEWSLETTER_NAV_QUERY = gql`
  query NewsletterNavQuery ($page: Integer!, $perPage: Integer!) {
    newsletters (page: $page, perPage: $perPage) {
      id
      oid
      title
      canonicalUrl
    }
  }
`;

export const NEWSLETTER_DELETE_MUTATION = gql`
  mutation NewsletterDeleteMutation ($input: NewsletterDeleteInput!) {
    newsletterDelete (input: $input)
    {
      success
      errors
    }
  }
`;

export const NEWSLETTER_RENDERING_OPTIONS = gql`
  query NewsletterRenderingOptions ($oid: Integer!, $page: Integer, $perPage: Integer, $filterString: [Str]) {
    newsletter (oid: $oid) {
      id
      renderingOptions(sortString: ["name asc"], page: $page, perPage: $perPage, filterString: $filterString) {
        id
        name
        value
      }
    }
  }
`;

export const NEWSLETTER_RENDERING_OPTION_ADD_MUTATION = gql`
  mutation NewsletterRenderingOptionAddMutation($input: NewsletterRenderingOptionCreateInput!) {
    newsletterRenderingOptionCreate(input: $input) {
      newsletterRenderingOption { id }
      success
      errors
    }
  }
`;

export const NEWSLETTER_RENDERING_OPTION_EDIT_MUTATION = gql`
  mutation NewsletterRenderingOptionEditMutation($input: NewsletterRenderingOptionUpdateInput!) {
    newsletterRenderingOptionUpdate(input: $input) {
      newsletterRenderingOptions { id }
      success
      errors
    }
  }
`;

export const NEWSLETTER_RENDERING_OPTION_DELETE_MUTATION = gql`
  mutation NewsletterRenderingOptionDeleteMutation($input: NewsletterRenderingOptionDeleteInput!) {
    newsletterRenderingOptionDelete(input: $input) {
      newsletterRenderingOptions { id }
      success
      errors
    }
  }
`;

export const NEWSLETTER_EDIT_QUERY = gql`
  query NewsletterEditQuery ($oid: Integer!) {
    newsletter (oid: $oid) {
      id
      oid
      title
      active
      frequency
      frequencyUnit
      publicationFrequency
      externalId
      internalId
      _issueStats {
        count
      }
      adminEmails
      description
      teaser
      topics {
        id
        oid
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        oid
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        oid
        title
        parent {
          id
          title
        }
      }
      zones {
        id
        oid
        refTitle
        title: refTitle
        xsltName
      }
      meta {
        id
        slug
        canonicalUrl
        browserTitle
        keywords
        description
        bottomText
        headers {
          id
          name
          value
        }
      }
      editors {
        id
        firstName
        lastName
      }
    }
  }
`;

export const NEWSLETTER_ADD_TAXONOMIES = gql`
  mutation NewsletterAddTaxonomies ($input: NewsletterAddTaxonomiesInput!) {
    newsletterAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const NEWSLETTER_REMOVE_TAXONOMIES = gql`
  mutation NewsletterRemoveTaxonomies ($input: NewsletterRemoveTaxonomiesInput!) {
    newsletterRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_ZONE_VALUE_ADD = gql`
  mutation NewsletterIssueZoneValueAddMutation($input: NewsletterIssueZoneValueCreateInput!) {
    newsletterIssueZoneValueCreate(input: $input) {
      newsletterIssueZoneValue {
        id
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_ZONE_VALUE_EDIT = gql`
  mutation NewsletterIssueZoneValueEditMutation($input: NewsletterIssueZoneValueUpdateInput!) {
    newsletterIssueZoneValueUpdate(input: $input) {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_ZONE_VALUE_DELETE = gql`
  mutation NewsletterIssueZoneValueDeleteMutation($input: NewsletterIssueZoneValueDeleteInput!) {
    newsletterIssueZoneValueDelete(input: $input) {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ZONE_ADD_MUTATION = gql`
  mutation NewsletterZoneAddMutation($input: NewsletterZoneCreateInput!) {
    newsletterZoneCreate(input: $input) {
      newsletterZone {
        id
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ZONE_EDIT_MUTATION = gql`
  mutation NewsletterZoneEditMutation($input: NewsletterZoneUpdateInput!) {
    newsletterZoneUpdate(input: $input) {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ZONE_DELETE_MUTATION = gql`
  mutation NewsletterZoneDeleteMutation($input: NewsletterZoneDeleteInput!) {
    newsletterZoneDelete(input: $input) {
      success
      errors
    }
  }
`;

export const NEWSLETTER_UPDATE = gql`
  mutation NewsletterUpdate($input: NewsletterUpdateInput!) {
    newsletterUpdate(input: $input) {
      success
      errors
      newsletters {
        id
        oid
      }
    }
  }
`;

export const NEWSLETTER_PRODUCTS_EDIT_QUERY = gql`
  query NewsletterProductsEditQuery($oid: Integer!) {
    newsletter (oid: $oid) {
      id
      products {
        id
        oid
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        productType
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
          description
        }
        subscriptionProduct {
          id
          licenseType
          duration
          durationUnit
          resource
          type
          itemId
        }
      }
    }
  }
`;

export const NEWSLETTER_SET_PRODUCTS = gql`
  mutation NewsletterSetProducts ($input: NewsletterSetProductsInput!) {
    newsletterSetProducts(input: $input){
      success
      errors
    }
  }
`;

export const NEWSLETTERS_MAIL_LIST_DISPATCH = gql`
  query NewslettersMailListDispatch($oid: Integer!, $perPage: Integer, $page: Integer) {
    newsletter (oid: $oid) {
      id
      _mailingListDispatchStats {
        count
      }
      mailingListDispatches(perPage: $perPage, page: $page,sortString:["createdAt desc"]) {
        id
        oid
        createdAt
        status
        sendCount
        action
        previousEmail
        errorMessage
        user {
          email
        }
      }
    }
  }
`;

export const NEWSLETTERS_ISSUE_DISPATCH = gql`
  query NewslettersIssueDispatch($oid: Integer!, $perPage: Integer, $page: Integer) {
    newsletter (oid: $oid) {
      id
      issues(sortString: ["createdAt desc"], perPage: $perPage, page: $page) {
        title
        dispatches(sortString: ["createdAt desc"]) {
          id
          createdAt
          updatedAt
          externalId
          status
          sendCount
          errorMessage
        }
        _dispatchStats {
          count
        }
      }
      _issueStats {
        count
      }
    }
  }
`;

export const NEWSLETTER_ISSUE_DISPATCH_UPDATE = gql`
  mutation NewsletterIssueDispatchUpdate($input: NewsletterIssueDispatchUpdateInput!) {
    newsletterIssueDispatchUpdate(input: $input) {
        newsletterIssueDispatches { id }
        success
        errors
    }
  }
`;

export const MAILING_LIST_DISPATCH_DELETE = gql`
  mutation MailingListDispatchDelete ($input: MailingListDispatchDeleteInput!) {
    mailingListDispatchDelete (input: $input)
    {
      mailingListDispatches { id }
      success
      errors
    }
  }
`;

export const NEWSLETTER_SET_EDITORS = gql`
  mutation NewsletterSetEditors ($input: NewsletterSetEditorsInput!) {
    newsletterSetEditors(input: $input) {
      newsletter {
        id
      }
      editors {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const NEWSLETTERS_LIST_QUERY = gql`
  query NewslettersListQuery ($page: Integer!, $perPage: Integer!, $filterString:[Str!]) {
    newsletters (page: $page, perPage: $perPage) {
      id
      title
      _childStats: _issueStats (filterString:$filterString) {
        count
      }
    }
    _newsletterStats {
      count
    }
  }
`;