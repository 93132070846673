import { BuildTreeType } from './types';

/**
 * Filter out items based on the ID if they are in the exlucsion list.
 *
 * @param {Array} notAllowedIdForSelection An array of ID's to filter out.
 * @param {Array} children An array of objects with at least an ID key.
 * @returns
 */
function processList(notAllowedIdForSelection: string[], children) {
  if (notAllowedIdForSelection.length) {
    children = children.filter((row) => notAllowedIdForSelection.indexOf(row.id) === -1);
  }
  return children;
}

/**
 * Map the query data object result to a structure the Antd Tree expects.
 * @param {params} params
 * @param {params.children} Children The taxonomy object.
 * @param {params.notAllowedIdForSelection} notAllowedIdForSelection A list of taxonomy ID's not allowed to be selected.
 */
export function buildTree({ children, notAllowedIdForSelection=[], titleField="title" }: BuildTreeType) {
  let childrenFiltered = processList(notAllowedIdForSelection, children);

  // Filter out taxonomies that are not selectable
  // childrenFiltered = childrenFiltered.filter((row) => row && row.selectable);

  // Merge two fields value to title when titleField contains more than one field
  const fieldParts = titleField.split(",");

  const tree = childrenFiltered.map((ch) => {
    const hasChildren = ch.hasOwnProperty('children') && Boolean(ch.children.length);
    const titleArr = fieldParts.map((field) => (ch[field]));

    const obj = {
      title: titleArr.filter((value) => value).join(' '),
      value: ch.id,
      key: ch.id,
      isLeaf: !ch._childStats || (ch._childStats && ch._childStats.count === 0),
      parent: ch?.parent?.title || '',
      parentId: ch?.parent?.id,
      rest: ch,
      disabled: ch.hasOwnProperty('allowSelect') ? !ch.allowSelect : false,
      typeName: ch?.__typename || '',
    };

    if (hasChildren) {
      const chFilteredChildren = processList(notAllowedIdForSelection, ch.children);
      obj['children'] = buildTree({ children: chFilteredChildren, notAllowedIdForSelection, titleField });
    } else {
      obj['children'] = [];
    }

    return obj;
  });
  return tree;
}
